<template>
  <b-card no-body>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        :height="400"
        :data="chartjsData.latestBarChart.data"
        :options="chartjsData.latestBarChart.options"
        :images-label="imagesLabel"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody,
} from 'bootstrap-vue'
import ChartjsComponentBarChart from '@/components/CartRecapulation/charts-components/ChartjsComponentBarChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BCard,
    BCardBody,
    ChartjsComponentBarChart,
  },
  props: {
    resultOfVotes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartjsData,
    }
  },
  computed: {
    imagesLabel() {
      return this.resultOfVotes.map(item => `${item.photo}chart.png`)
    },
  },
  created() {
    const labels = this.resultOfVotes.map((_item, idx) => _item.no_urut)
    const dataCounts = this.resultOfVotes.map(item => item.result?.count || 0)
    const totalVotes = dataCounts.reduce((previousValue, currentValue) => previousValue + currentValue || 0, 0)
    this.chartjsData.latestBarChart.data.labels = labels
    this.chartjsData.latestBarChart.data.datasets[0].data = dataCounts
    this.chartjsData.latestBarChart.options.scales.yAxes[0].ticks.stepSize = 1000
    this.chartjsData.latestBarChart.options.scales.yAxes[0].ticks.max = totalVotes
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
